
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[state]/[[...slug]]",
      function () {
        return require("private-next-pages/[state]/[[...slug]].page.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[state]/[[...slug]]"])
      });
    }
  